// CONTENT URL
export const getAllContentUrl = (site, locale, collection) => `/cms/public/${site}/${locale}/collection/key/${collection}/content`;
// PROMOTION URL
export const getPromotionVoucherUrl = (keyPolicy) => `/promotion/policy/key/${keyPolicy}/voucher`;
// AI TOOL, CHATBOTS URL
export const askAiQuestionUrl = () => '/artificial-intelligence/question/question-bot';
// MEMBERSHIP URL
export const membershipSubcriptionUrl = '/membership/subcriptions';
// WALLET JOURNAL TRANSACTION
export const walletJournalTransaction = () => '/wallet-journal/transaction/search';
// WALLET MODULE
export const getUserWalletsUrl = (user) => `/wallet-journal/account/user/${user}/all`;
// REF-CODE MEMBERSHIP
export const redeem = () => '/membership/ref-code/redeem';
export const validateRefCode = () => '/membership/ref-code/validate';
export const updateZepUserApi = (userId) => `/healthgpt/user/${userId}`;
// TRACK
export const createUserDeviceUrl = '/user-management/user-device/add-device';
export const updatePermissionDeviceUrl = (deviceId) => `/user-management/user-device/${deviceId}/permissions`;
// FORM
export const searchFormResponse = () => `/form-server/form-response/search`;
// PERSONAL DOCTOR
export const getPersonalDoctor = (patientId) => `/user-management/personal-doctor/patient/${patientId}`;
export const getPersonalPatientDoctor = (doctorId) => `/user-management/personal-doctor/doctor/${doctorId}`;
