import { useContext } from '@nuxtjs/composition-api';
import { Device } from '@capacitor/device';
import { createUserDeviceUrl, updatePermissionDeviceUrl } from './url';
export function useDeviceTracking() {
    const { $axios, $toast } = useContext();
    const addDevice = async () => {
        try {
            const deviceId = await Device.getId();
            const deviceInfo = await Device.getInfo();
            await $axios.post(createUserDeviceUrl, {
                info: deviceInfo,
                deviceId: deviceId.identifier
            });
        }
        catch (error) {
            $toast.global.appError({
                message: error.message
            });
        }
    };
    const updatePermission = async (payload) => {
        try {
            const deviceId = await Device.getId();
            await $axios.put(updatePermissionDeviceUrl(deviceId.identifier), payload);
        }
        catch (error) {
            $toast.global.appError({
                message: error.message
            });
        }
    };
    return {
        addDevice,
        updatePermission
    };
}
