var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{style:({
    position: 'relative'
  }),attrs:{"fullscreen":"","transition":"slide-y-reverse-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"white--text d-flex flex-column rounded-0",style:({
      backgroundColor: 'var(--v-primary-base)',
      paddingBottom: '150px !important'
    }),attrs:{"rounded":"tile"}},[_c('v-card-title',{style:({ paddingTop: _vm.safeArea.top + 20 + 'px' })},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("$close")])],1)],1),_vm._v(" "),_c('v-card-title',{staticClass:"text-h5 font-weight-bold pt-10"},[_vm._v(_vm._s(_vm.$t('account member'))+"\n    ")]),_vm._v(" "),_c('v-card-text',{staticClass:"white--text",staticStyle:{"display":"flex","flex-direction":"column","gap":"10px"}},_vm._l((_vm.defaultBenefit),function(item,index){return _c('div',{key:'d-' + index,staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v(" $checkCircle ")]),_vm._v(" "),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.$t(item.content)))])],1)}),0),_vm._v(" "),_c('v-card-text',{staticClass:"corporate-content white--text text-center"},[_c('ActivateMembershipCode',{attrs:{"outlined":"","persistent-hint":"","hide-details":"auto","color":"white","dark":"","user-infor":_vm.userInfor},on:{"on-close":_vm.handleCloseDialog}}),_vm._v(" "),_c('div',{staticClass:"text-subtitle-1"},[_vm._v("\n        "+_vm._s(_vm.$t('get the code from the company hr'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.$t('or')))]),_vm._v(" "),_c('div',{staticClass:"text-subtitle-1",domProps:{"innerHTML":_vm._s(_vm.$t('register on wellcare website'))}})],1),_vm._v(" "),_c('v-card',{staticClass:"px-4 pt-4 pb-6",style:({
        position: 'fixed',
        right: '0',
        left: '0',
        bottom: '0',
        backgroundColor: 'var(--v-primary-base)'
      }),attrs:{"elevation":"0"}},[_c('v-btn',{staticClass:"font-weight-bold mt-2 rounded-lg text-uppercase",attrs:{"large":"","block":"","color":"white","outlined":""},on:{"click":_vm.onClose}},[_vm._v(_vm._s(_vm.$t('do it later')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }