import { defineComponent, ref, useContext, useRoute, watch, computed } from '@nuxtjs/composition-api';
import { StatusBar } from '@capacitor/status-bar';
import useDeepLink from '../composables/use-deeplink';
import useApp from '../composables/use-app';
export default defineComponent({
    // components: {
    //   Bubble
    // },
    setup() {
        useDeepLink();
        const { padding } = useApp();
        const { $vuetify, i18n } = useContext();
        const bottomMenu = ref(null);
        const items = computed(() => [
            {
                text: i18n.t('home').toString(),
                icon: '$homeActive',
                activeIcon: '$homeActive',
                to: '/provider'
            },
            {
                text: i18n.t('my patient').toString(),
                icon: '$medicalReceipt',
                activeIcon: '$medicalReceipt',
                to: '/provider/patient'
            }
            // {
            //   text: i18n.t('membership').toString(),
            //   icon: '$membership',
            //   activeIcon: '$membership',
            //   to: '/membership'
            // }
            /*
            {
              text: 'EduHub',
              icon: '$bulb',
              activeIcon: '$bulb',
              to: '/provider/edu-hub'
            }
            */
        ]);
        StatusBar.setBackgroundColor({
            color: $vuetify.theme.themes.light.primary.toString()
        });
        StatusBar.setOverlaysWebView({ overlay: true });
        const route = useRoute();
        const activeRoute = ref('');
        watch(route, () => {
            activeRoute.value = route.value.path;
        }, { immediate: true });
        return { items, bottomMenu, activeRoute, padding };
    }
});
