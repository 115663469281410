/* eslint-disable camelcase */
import { useContext, useStore, useRouter } from '@nuxtjs/composition-api';
import { Capacitor } from '@capacitor/core';
import { CapacitorCallkeep } from '@wellcare/capacitor-callkeep';
import { useLog } from '@wellcare/vue-component';
import { usePreferences } from './use-preferences';
import { useOneSignal } from '~/services/use-onesignal';
// function sleep(time: number) {
//   return new Promise((resolve, _reject) => {
//     setTimeout(() => resolve(this), time)
//   })
// }
function encodeToBase64(data) {
    const string = JSON.stringify(data);
    const endcoder = new TextEncoder();
    const stringEncode = endcoder.encode(string);
    const stringBinary = String.fromCharCode.apply(null, stringEncode);
    return window.btoa(stringBinary);
}
const useCallkeep = ({ isIos }) => {
    const { log } = useLog();
    const { set, get } = usePreferences();
    const { $config } = useContext();
    const { getters, commit } = useStore();
    const { registerDevice } = useOneSignal();
    let currCall = null;
    const router = useRouter();
    const isIOS = Capacitor.getPlatform() === 'ios';
    const checkCallUuid = async (data) => {
        const currCallStorage = await get({ key: 'call_uuid' });
        if (isIOS && !currCallStorage.value)
            return false;
        return !(data.uuid === currCall);
    };
    const handleCallEvent = async (data) => {
        var _a, _b, _c, _d, _e, _f;
        try {
            const check = await checkCallUuid(data);
            if (!check)
                return;
            currCall = data.uuid;
            // we need to reduce the size of the object to comply with the query string size limit
            const dataFormat = {
                conversation: data.conversation,
                consultation: data.consultation,
                mode: data.mode,
                appid: data.appid,
                cname: (_a = data.cname) !== null && _a !== void 0 ? _a : data.channel,
                token: data.token,
                user: {
                    name: (_b = data === null || data === void 0 ? void 0 : data.from) === null || _b === void 0 ? void 0 : _b.name,
                    _id: (_c = data === null || data === void 0 ? void 0 : data.from) === null || _c === void 0 ? void 0 : _c._id,
                    isProvider: (_d = data === null || data === void 0 ? void 0 : data.from) === null || _d === void 0 ? void 0 : _d.isProvider,
                    avatar: {
                        url: (_e = data === null || data === void 0 ? void 0 : data.from) === null || _e === void 0 ? void 0 : _e.avatar
                    },
                    provider: (_f = data === null || data === void 0 ? void 0 : data.from) === null || _f === void 0 ? void 0 : _f.provider
                }
            };
            const endcodeData = encodeToBase64(dataFormat);
            router.push('/redirect?data=' + endcodeData);
        }
        catch (e) {
            console.log('[Wellcare debug] Error: ' + e.message);
        }
    };
    const endIncomingCall = async () => {
        // eslint-disable-line
        const callId = await get({ key: 'call_uuid' });
        log({
            message: 'Invoke end incoming call',
            context: 'useCallkeep.endIncomingCall',
            data: { callId }
        });
        if (callId)
            return CapacitorCallkeep.endCall({ callId: callId.value })
                .then(() => {
                log({
                    message: 'End incoming call success',
                    context: 'useCallkeep.endIncomingCall',
                    data: { callId }
                });
            })
                .catch((error) => {
                log({
                    message: 'End incoming call failed',
                    context: 'useCallkeep.endIncomingCall',
                    data: error
                });
            });
    };
    const initializeCallkeep = () => {
        const shouldInitializeCallkeep = getters.getField('shouldInitializeCallkeep');
        if (!shouldInitializeCallkeep)
            return;
        const APP_ID = isIos ? $config.onesignal.voipAppId : $config.onesignal.appId;
        CapacitorCallkeep.checkCurrentCall().then((data) => {
            log({ message: 'checkCurrentCall', data, context: 'Callkeep' });
            handleCallEvent(data);
        });
        CapacitorCallkeep.addListener('push', (data) => {
            log({ message: 'push', data, context: 'Callkeep' });
        });
        CapacitorCallkeep.addListener('exception', (data) => {
            log({ message: 'exception', data, context: 'Callkeep' });
        });
        CapacitorCallkeep.addListener('trace', (data) => {
            log({ message: 'trace', data, context: 'Callkeep' });
        });
        CapacitorCallkeep.addListener('onCallAccepted', (data) => {
            log({ message: 'onCallAccepted', data, context: 'Callkeep' });
            handleCallEvent(data);
        });
        CapacitorCallkeep.addListener('onCallRejected', (data) => {
            var _a, _b, _c;
            log({ message: 'onCallRejected', data, context: 'Callkeep' });
            if (data.uuid)
                currCall = data.uuid;
            const customData = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.payload) === null || _a === void 0 ? void 0 : _a.custom) === null || _b === void 0 ? void 0 : _b.a) === null || _c === void 0 ? void 0 : _c.data;
            if (customData) {
                currCall = customData.uuid;
            }
        });
        CapacitorCallkeep.addListener('push', (data) => {
            log({ message: 'onCallRejected', data, context: 'Callkeep' });
        });
        CapacitorCallkeep.addListener('registration', async (data) => {
            log({ message: 'registration', data, context: 'Callkeep' });
            const identifier = await get({ key: 'identifier' });
            if (!identifier.value || identifier.value !== data.token) {
                await set({ key: 'identifier', value: data.token });
                const user = getters.getField('authen').user;
                if (isIOS) {
                    await registerDevice({
                        identifier: identifier.value,
                        external_user_id: user._id,
                        appId: $config.onesignal.voipAppId
                    });
                }
            }
        });
        CapacitorCallkeep.initialize({
            appId: APP_ID
        }).then(() => {
            log({
                message: 'initialized!',
                context: 'CallKeep',
                data: {
                    appId: APP_ID
                }
            });
        });
        commit('updateField', {
            path: 'shouldInitializeCallkeep',
            value: false
        });
    };
    const muteCall = (callId) => {
        log({
            message: 'Invoke muteCall call',
            context: 'useCallkeep.endIncomingCall',
            data: { callId }
        });
        return CapacitorCallkeep.muteCall({ callId })
            .then(() => {
            log({
                message: 'End muteCall success',
                context: 'useCallkeep.endIncomingCall',
                data: { callId }
            });
        })
            .catch((error) => {
            log({
                message: 'End imuteCall failed',
                context: 'useCallkeep.endIncomingCall',
                data: error
            });
        });
    };
    const unmuteCall = (callId) => {
        log({
            message: 'Invoke unmuteCall call',
            context: 'useCallkeep.endIncomingCall',
            data: { callId }
        });
        return CapacitorCallkeep.unmuteCall({ callId })
            .then(() => {
            log({
                message: 'End unmuteCall success',
                context: 'useCallkeep.endIncomingCall',
                data: { callId }
            });
        })
            .catch((error) => {
            log({
                message: 'End unmuteCall failed',
                context: 'useCallkeep.endIncomingCall',
                data: error
            });
        });
    };
    return { initializeCallkeep, endIncomingCall, unmuteCall, muteCall };
};
export default useCallkeep;
