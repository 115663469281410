import { render, staticRenderFns } from "./consultation.vue?vue&type=template&id=530da4bc"
import script from "./consultation.vue?vue&type=script&lang=ts"
export * from "./consultation.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WPermissionsProvider: require('/usr/src/app/node_modules/@wellcare/vue-component/components/global/permissions-provider.vue').default,WConnection: require('/usr/src/app/src/components/w/connection.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VApp,VAppBar,VAppBarNavIcon,VBtn,VListItem,VMain,VNavigationDrawer})
