import { getField, updateField } from 'vuex-composition-map-fields';
const state = () => ({
    shouldInitializeCallkeep: true,
    isShowUpdatePrompt: true,
    app: {
        device: {},
        safeArea: {},
        signature: ''
    },
    authen: {
        user: null,
        token: ''
    },
    userState: {},
    logs: [],
    wallets: [],
    registerAvatar: {
        userFile: null,
        relationshipFile: null
    }
});
const getters = {
    getField
};
const mutations = {
    updateField
};
export default {
    namespaced: true,
    strict: false,
    state,
    getters,
    mutations
};
