import { Capacitor } from '@capacitor/core';
import { useAuth } from '@wellcare/nuxt-module-account/repositories';
import { CapacitorCallkeep } from '@wellcare/capacitor-callkeep';
import OneSignal from 'onesignal-cordova-plugin';
import { useContext } from '@nuxtjs/composition-api';
import { useOneSignal } from '~/services/use-onesignal';
import { usePreferences } from '~/composables/use-preferences';
/**
 * Handle extra logic when login, logout in mobile from authentication module.
 * @returns {{executeSignOut: Function, checkDataSignIn: Function}}
 */
const redirect = async (url) => {
    const { get } = usePreferences();
    const redirectStep = await get({ key: 'redirectStep' });
    const baseUrl = redirectStep.value ? atob(redirectStep.value) : url;
    window.location.href = baseUrl;
};
export function useAuthHandle() {
    const { $config, $toast } = useContext();
    const { signOut, user } = useAuth();
    const { set, clear, get } = usePreferences();
    const { registerDevice, deleteUserVoIP } = useOneSignal();
    // const router = useRouter()
    /**
     * Function to signout from app
     */
    const executeSignOut = async () => {
        try {
            const identifier = await get({ key: 'identifier' });
            const usePreferences = await get({ key: 'user' });
            if (Capacitor.getPlatform() !== 'web') {
                OneSignal.logout();
                if (Capacitor.getPlatform() === 'ios') {
                    await deleteUserVoIP(user.value._id, identifier.value);
                }
                else if (Capacitor.getPlatform() === 'android') {
                    await CapacitorCallkeep.logoutOneSignal();
                }
            }
            if (Capacitor.getPlatform() === 'android') {
                await CapacitorCallkeep.logoutOneSignal();
            }
            localStorage.clear();
            // clear all, but identifier & user
            await clear();
            await set({ key: 'identifier', value: identifier.value });
            await set({ key: 'user', value: usePreferences.value });
        }
        catch (error) {
            $toast.global.appError({ message: (error === null || error === void 0 ? void 0 : error.message) || 'Unexpected Error.' });
            console.error(error);
        }
        finally {
            await signOut();
        }
    };
    /**
     * Check & save data after user signin success.
     * You should call this function in home page.
     */
    const checkDataSignIn = async () => {
        try {
            const app = localStorage.getItem('consultation/app');
            const appPref = await get({ key: 'app' });
            const isProvider = user.value.isProvider;
            const identifier = await get({ key: 'identifier' });
            const redirectApp = await get({ key: 'redirectApp' });
            // register user in VoIP onesignal project
            if (identifier.value && Capacitor.getPlatform() === 'ios') {
                await registerDevice({
                    identifier: identifier.value,
                    external_user_id: user.value._id,
                    appId: $config.onesignal.voipAppId
                });
            }
            if (Capacitor.getPlatform() === 'android') {
                await CapacitorCallkeep.initialize({
                    appId: $config.onesignal.appId
                });
            }
            if (!app || !appPref.value) {
                if (isProvider) {
                    localStorage.setItem('consultation/app', 'provider');
                    await set({ key: 'app', value: 'provider' });
                    // TODO: Redirect to home page if user is provider
                    // !!! NOTE: not use router push because we want page reload at first
                    redirect('/provider');
                }
                else {
                    localStorage.setItem('consultation/app', 'patient');
                    await set({ key: 'app', value: 'patient' });
                    // TODO: Redirect to home page if user is patient
                    // !!! NOTE: same
                    const redirectTo = redirectApp.value ? atob(redirectApp.value) : '/';
                    window.location.href = redirectTo;
                }
            }
            else {
                redirect('/');
            }
        }
        catch (error) {
            console.error(error);
            $toast.global.appError({ message: (error === null || error === void 0 ? void 0 : error.message) || 'Unexpected Error.' });
            executeSignOut();
        }
    };
    return {
        executeSignOut,
        checkDataSignIn
    };
}
