/* eslint-disable camelcase */
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { useContext } from '@nuxtjs/composition-api';
import axios from 'axios';
const baseURL = 'https://onesignal.com';
export function useOneSignal() {
    const { $axios } = useContext();
    /**
     * Register a new device to one of OneSignal apps. Then, the user is able to receive notifications from that app.
     * @deprecated
     * @param data
     * @returns Promise Axios response
     */
    const registerDevice = async (data) => {
        const appInfor = await App.getInfo();
        const device = await Device.getInfo();
        let device_type = 0;
        if (Capacitor.getPlatform() === 'android')
            device_type = 1;
        return axios.request({
            baseURL,
            method: 'post',
            url: '/api/v1/players',
            headers: {},
            data: {
                app_id: data.appId,
                identifier: data.identifier,
                device_type,
                external_user_id: data.external_user_id,
                game_version: appInfor.version,
                device_model: device.model,
                device_os: device.osVersion
                // test_type: data.testType || OneSingalTestType.ADHOC
            }
        });
        // return axios.request({
        //   baseURL,
        //   method: 'post',
        //   url: `/api/v1/apps/${data.appId}/users`,
        //   headers: {},
        //   data: {
        //     identity: {
        //       external_id: data.external_user_id
        //     },
        //     subscriptions: [
        //       {
        //         type: 'iOSPush',
        //         token: data.identifier,
        //         app_version: '2.24.4'
        //       }
        //     ]
        //   }
        // })
    };
    /**
     * Remove user record from app in OneSignal. Afterward, the user will not receive notifications from this app.
     * @param player_id
     * @param app_id
     * @returns Promise AxiosResponse
     */
    const deletePlayerRecord = (player_id, app_id) => {
        return axios.request({
            baseURL,
            method: 'DELETE',
            url: '/api/v1/players/' + player_id,
            params: {
                app_id
            }
        });
    };
    /**
     * Remove user from VoIP app in OneSignal, only call this in IOS platform.
     * @param userId
     * @returns Promise AxiosResponse
     */
    const deleteUserVoIP = (userId, token) => {
        return $axios.post('/api/user/' + userId + '/delete-voip-device', {
            token
        });
    };
    /**
     * @function createUser
     * @description Create a new user using the OneSignal API.
     * @see {@link https://documentation.onesignal.com/reference/create-user}
     * @param {string} appId - OneSignal App ID.
     * @param {object} data - The data body payload.
     * @returns {Promise<void>} A promise that resolves when the user is created.
     */
    const createUser = async (appId, data) => {
        try {
            const appInfor = await App.getInfo();
            const device = await Device.getInfo();
            const payload = {
                identity: {
                    external_id: data.user
                },
                subscriptions: [
                    {
                        token: data.token,
                        app_version: appInfor.version,
                        device_model: device.model,
                        device_os: device.osVersion,
                        type: data.type,
                        enabled: true
                    }
                ]
            };
            const response = await axios.post(`https://onesignal.com/api/v1/apps/${appId}/users`, payload);
            console.log(response.data);
        }
        catch (error) {
            console.error('Error creating user:', error.response.data);
        }
    };
    /**
     * @function deleteUser
     * @description Delete a user using the OneSignal API.
     * @see {@link https://documentation.onesignal.com/reference/delete-user}
     * @param {string} externalId - Unique identifier of the user to be deleted.
     * @param {string} appId - OneSignal App ID.
     * @returns {Promise<void>} A promise that resolves when the user is deleted.
     */
    const deleteUser = async (externalId, appId) => {
        try {
            const response = await axios.delete(`https://onesignal.com/api/v1/apps/${appId}/users/by/external_id/${externalId}`);
            console.log(response.data);
        }
        catch (error) {
            console.error('Error deleting user:', error.response.data);
        }
    };
    return {
        registerDevice,
        deletePlayerRecord,
        deleteUserVoIP,
        createUser,
        deleteUser
    };
}
