import { computed, reactive, useContext, useRoute } from '@nuxtjs/composition-api';
export const useQuery = () => {
    var _a, _b;
    const route = useRoute();
    const { $config } = useContext();
    const filterStatus = $config.eduHubStatus.split(',').map((status) => {
        return {
            match_phrase: {
                'page.properties.Status.keyword': status
            }
        };
    });
    const categoryQuery = {
        _source: {
            include: [
                'id',
                'page.cover.url',
                'page.properties.Name',
                'page.properties.Description'
            ]
        },
        query: {
            bool: {
                filter: [
                    {
                        bool: {
                            should: filterStatus
                        }
                    },
                    {
                        term: {
                            'page.properties.Type.keyword': 'Category'
                        }
                    }
                ]
            }
        }
    };
    const categoryAggs = computed(() => {
        return {
            size: 0,
            aggs: {
                ids: {
                    terms: {
                        field: 'page.properties.Parent item.id.keyword'
                    }
                }
            },
            query: {
                bool: {
                    filter: [
                        {
                            bool: {
                                should: filterStatus
                            }
                        },
                        {
                            term: {
                                'page.properties.Type.keyword': 'Topic'
                            }
                        }
                    ]
                }
            }
        };
    });
    const topicQuery = reactive({
        from: 0,
        size: 20,
        _source: {
            include: [
                'id',
                '_id',
                'page.cover.url',
                'page.properties.Name',
                'page.properties.Slug',
                'page.properties.Description'
            ]
        },
        query: {
            bool: {
                filter: [
                    {
                        bool: {
                            should: filterStatus
                        }
                    },
                    {
                        term: {
                            'page.properties.Parent item.id.keyword': (_a = route.value.params) === null || _a === void 0 ? void 0 : _a.id
                        }
                    },
                    {
                        term: {
                            'page.properties.Type.keyword': 'Topic'
                        }
                    }
                ]
            }
        },
        sort: [
            {
                'page.properties.Slug.keyword': {
                    order: 'asc'
                }
            }
        ]
    });
    const topicAggs = computed(() => {
        return {
            size: 0,
            aggs: {
                ids: {
                    terms: {
                        field: 'page.properties.Parent item.id.keyword',
                        size: 1000
                    },
                    aggs: {
                        deliveries: {
                            terms: {
                                field: 'page.properties.Delivery.keyword'
                            }
                        }
                    }
                }
            }
        };
    });
    const queryAllTopic = computed(() => {
        var _a;
        return {
            size: 1000,
            _source: {
                include: ['id', '_id']
            },
            query: {
                bool: {
                    filter: [
                        {
                            bool: {
                                should: filterStatus
                            }
                        },
                        {
                            term: {
                                'page.properties.Parent item.id.keyword': (_a = route.value.params) === null || _a === void 0 ? void 0 : _a.id
                            }
                        },
                        {
                            term: {
                                'page.properties.Type.keyword': 'Topic'
                            }
                        }
                    ]
                }
            }
        };
    });
    const queryMedia = reactive({
        _source: {
            include: [
                'page.cover.url',
                'page.properties.Name',
                'page.properties.Labels',
                'page.properties.Delivery',
                'page.properties.PublishedAt',
                'page.properties.Files.*',
                'page.properties.Patient.*',
                'page.properties.Providers.*'
            ]
        },
        sort: [
            {
                _script: {
                    type: 'number',
                    script: {
                        lang: 'painless',
                        source: `
              if (!doc.containsKey('page.properties.Delivery.keyword') || doc['page.properties.Delivery.keyword'].size() == 0) {
                return 2;
              } else {
                String delivery = doc['page.properties.Delivery.keyword'].value;
                if (delivery == 'Short Videos') {
                  return 0;
                } else if (delivery == 'Podcasts') {
                  return 1;
                } else {
                  return 2;
                }
              }
            `
                    },
                    order: 'asc'
                }
            },
            {
                'page.properties.Name.keyword': {
                    order: 'asc'
                }
            }
        ],
        from: 0,
        size: 20,
        query: {
            bool: {
                filter: [
                    {
                        bool: {
                            should: filterStatus
                        }
                    },
                    {
                        term: {
                            'page.properties.Parent item.id.keyword': (_b = route.value.params) === null || _b === void 0 ? void 0 : _b.id
                        }
                    },
                    {
                        term: {
                            'page.properties.Type.keyword': 'Item'
                        }
                    }
                ]
            }
        }
    });
    return {
        categoryQuery,
        categoryAggs,
        topicQuery,
        topicAggs,
        queryAllTopic,
        queryMedia
    };
};
